html, body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: courier;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
}

.container {
  align-items: center;
  background-color: rgb(49, 40, 40);
  color: white;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-evenly;
  width: 100%;
}